import { FIRMWARE_URL } from './constants';
import axios from './interceptors';

export const fetchAllFirmwareVersions = () => {
	return axios.get(`${FIRMWARE_URL}/all`);
};

export const deleteFirmwareVersion = async (id: string) => {
	return await axios.delete(`${FIRMWARE_URL}/delete/${id}`);
};
