import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	DialogProps,
	DialogContentText,
	makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import Spinner from '../accessories/Spinner';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
	disabledButtons: {
		fontWeight: 600,
		color: '#afafaf',
	},
});

type FirmwareDialogProps = {
	handleClose: () => void;
	open: any;
	firmware: any;
	deleteFirmware: (id: string) => void;
} & DialogProps;

const DeleteFirmwareDialog = ({ handleClose, open, firmware, deleteFirmware }: FirmwareDialogProps) => {
	const classes = useStyles();
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const [isDeleteEnable, setIsDeleteEnable] = useState<boolean>(false);

	const onClose = async ({ currentTarget: target }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (target.name === 'delete' && firmware.uuid && isDeleteEnable) {
			await deleteFirmware(firmware.uuid);
		}
		setIsDeleteEnable(false);
		handleClose();
	};

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setIsDeleteEnable(target.value.toUpperCase() === 'DELETE' ? true : false);
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Delete Firmware</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Do you really want to delete the firmware with description{' '}
					<span style={{ fontWeight: 'bold' }}>{firmware?.description}</span>? Please type{' '}
					<span style={{ color: 'red' }}>Delete</span> in the field below to confirm this action.
				</DialogContentText>
				<TextField
					name="delete"
					margin="dense"
					label="Type the word Delete here"
					placeholder="Type the word Delete here"
					type="text"
					onChange={handleChange}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				{loading && <Spinner size={0.9} borderWidth=".15rem" />}
				<Button
					name="delete"
					className={!isDeleteEnable ? classes.disabledButtons : classes.buttons}
					onClick={
						isDeleteEnable
							? onClose
							: () => {
									//do nothing
							  }
					}
					color="primary"
					size="small"
					disabled={loading && !isDeleteEnable}
				>
					Delete
				</Button>
				<Button name="cancel" className={classes.buttons} onClick={onClose} color="secondary" size="small">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteFirmwareDialog;
