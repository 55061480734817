import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ITokenRequestBody from '../../model/Token';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
	tokenCreation: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	},
	dialogActions: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
	},
});

type Props = {
	open: boolean;
	handleClose: () => void;
	createToken: (token: ITokenRequestBody) => void;
};

const inputsInitialState = {
	description: { value: '', errorMessage: 'Token description is required!', inError: false },
	name: { value: '', errorMessage: 'Token name is required!', inError: false },
};

const CreateTokenDialog = ({ open, handleClose, createToken }: Props) => {
	const classes = useStyles();

	const [inputs, setInputs] = useState<any>(inputsInitialState);

	const handleInputChange = ({ target: { name, value } }: any) => {
		setInputs((prev: any) => ({ ...prev, [name]: { ...prev[name], value } }));
	};

	const resetErrorText = ({ target: { name } }: any) => {
		if (inputs[name].inError) {
			setInputs((prev: any) => ({ ...prev, [name]: { ...prev[name], inError: false } }));
		}
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (inputs.description.value === '' || inputs.name.value === '') {
			setInputs((prev: any) => ({
				...prev,
				description: { ...prev.description, inError: inputs.description.value === '' },
			}));
			setInputs((prev: any) => ({ ...prev, name: { ...prev.name, inError: inputs.name.value === '' } }));
			return;
		}

		createToken({
			name: inputs.name.value,
			description: inputs.description.value,
		});
		handleClose();
	};

	useEffect(() => {
		if (!open) setInputs(inputsInitialState);
	}, [open]);

	return (
		<Dialog onClose={() => handleClose()} open={open}>
			<DialogTitle>Create Token</DialogTitle>
			<DialogContent>
				<form className={classes.tokenCreation} onSubmit={handleSubmit} autoComplete="off">
					<TextField
						error={Boolean(inputs.name.inError)}
						helperText={inputs.name.inError ? inputs.name.errorMessage : ' '}
						inputProps={{ name: 'name', onFocus: resetErrorText }}
						label="Token name*"
						onChange={handleInputChange}
						fullWidth
					/>
					<TextField
						error={Boolean(inputs.description.inError)}
						helperText={inputs.description.inError ? inputs.description.errorMessage : ' '}
						inputProps={{ name: 'description', onFocus: resetErrorText }}
						label="Token description*"
						onChange={handleInputChange}
						fullWidth
					/>
					<DialogActions className={classes.dialogActions}>
						<Button className={classes.buttons} color="primary" type="submit">
							Create Token
						</Button>
						<Button className={classes.buttons} color="secondary" onClick={() => handleClose()}>
							Cancel
						</Button>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default CreateTokenDialog;
