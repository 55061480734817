import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import MaterialTable from 'material-table';
import { Button, Paper } from '@material-ui/core';
import { createApiToken, fetchApiTokens, deleteApiToken } from '../../api/api-token';
import { toast } from 'react-toastify';
import CreateTokenDialog from './CreateTokenDialog';
import DeleteTokenDialog from './DeleteTokenDialog';
import Toolbar from '../shared/Toolbar';
import Pagination from '../shared/Pagination';
import ITokenRequestBody from '../../model/Token';
import useStyles from '../shared/useStyles';

const Token = () => {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [tokens, setTokens] = useState([]);
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
	const [tokenToDelete, setTokenToDelete] = useState<ITokenRequestBody>({} as ITokenRequestBody);

	const columns: any = [
		{ title: 'Token', field: 'token', filtering: false },
		{ title: 'Name', field: 'customerId', defaultSort: 'asc' },
		{ title: 'Description', field: 'description' },
	];

	const fetchTokens = () => {
		setLoading(true);
		fetchApiTokens()
			.then(({ data }) => {
				setTokens(data);
			})
			.catch((error) => {
				toast.error(error.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const createToken = (token: ITokenRequestBody) => {
		setLoading(true);
		createApiToken(token)
			.then(() => {
				toast.success('Token successfully created!', {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				fetchTokens();
			})
			.catch((error) => {
				toast.error(error.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				setLoading(false);
			});
	};

	const deleteToken = (token: string) => {
		setLoading(true);
		deleteApiToken(token)
			.then(() => {
				toast.success('Token successfully deleted!', {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				fetchTokens();
			})
			.catch((error) => {
				toast.error(error.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchTokens();
	}, []);

	const handleClose = () => {
		setIsOpenDialog(false);
		setIsOpenDialogDelete(false);
	};

	return (
		<Layout>
			<MaterialTable
				style={{ width: '100%' }}
				data={tokens}
				columns={columns}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					search: false,
					filtering: true,
				}}
				components={{
					Container: (props) => <Paper className={classes.tableContainer} elevation={0} {...props} />,
					Toolbar: (props) => (
						<Toolbar className={classes.tableToolbar} {...props}>
							<Button className={classes.pilabsRoundedBtn} onClick={() => setIsOpenDialog(true)}>
								Create&nbsp;Token
							</Button>
						</Toolbar>
					),
					Pagination: (props) => <Pagination {...props} />,
				}}
				actions={[
					(t) => ({
						icon: 'delete',
						tooltip: 'Delete token',
						onClick: (event, t: any) => {
							let token: ITokenRequestBody = {} as ITokenRequestBody;
							token.id = t.id;
							token.name = t.name;
							token.description = t.description;
							setTokenToDelete(token);
							setIsOpenDialogDelete(true);
						},
					}),
				]}
			/>
			<CreateTokenDialog open={isOpenDialog} handleClose={handleClose} createToken={createToken} />
			<DeleteTokenDialog
				open={isOpenDialogDelete}
				handleClose={handleClose}
				token={tokenToDelete}
				deleteToken={deleteToken}
			/>
		</Layout>
	);
};

export default Token;
