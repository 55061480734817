import React, { useEffect, useState } from 'react';
import { fetchAllFirmwareVersions, deleteFirmwareVersion } from '../../api/firmware';
import Layout from '../layout/Layout';
import MaterialTable from 'material-table';
import useStyles from '../shared/useStyles';
import { toast } from 'react-toastify';
import Toolbar from '../shared/Toolbar';
import Pagination from '../shared/Pagination';
import { Paper } from '@material-ui/core';
import DeleteFirmwareDialog from './DeleteFirmwareDialog';

const Firmware = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [versions, setVersions] = useState([]);
	const [firmwareToDelete, setFirmwareToDelete] = useState({});
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const columns = [
		{ title: 'Description', field: 'description' },
		{ title: 'API\u00A0Token', field: 'api-token' },
		{ title: 'FW\u00A0Version', field: 'fw-version' },
		{ title: 'HW\u00A0Version', field: 'hw-version' },
		{ title: 'App\u00A0Version', field: 'app-version' },
		{ title: 'BL\u00A0Version', field: 'bl-version' },
		{ title: 'SD\u00A0Version', field: 'sd-version' },
		{ title: 'SDK\u00A0Version', field: 'sdk-version' },
	];
	useEffect(() => {
		setLoading(true);
		fetchAllFirmwareVersions()
			.then(({ data }) => {
				setVersions(data);
			})
			.catch((error) => {
				toast.error(error.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
			})
			.finally(() => setLoading(false));
	}, []);

	const handleClose = () => {
		setIsOpenDialog(false);
	};

	const deleteFirmware = (id: string) => {
		setLoading(true);
		deleteFirmwareVersion(id)
			.then(() => {
				toast.success('Firmware successfully deleted!', {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				fetchAllFirmwareVersions()
					.then(({ data }) => {
						setVersions(data);
					})
					.catch((error) => {
						toast.error(error.message, {
							autoClose: 3000,
							position: toast.POSITION.BOTTOM_LEFT,
						});
					})
					.finally(() => setLoading(false));
			})
			.catch((error) => {
				toast.error(error.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				setLoading(false);
			});
	};

	return (
		<Layout>
			<MaterialTable
				style={{ width: '100%' }}
				data={versions}
				columns={columns}
				isLoading={loading}
				options={{
					actionsColumnIndex: -1,
					search: false,
					filtering: true,
				}}
				components={{
					Container: (props) => <Paper className={classes.tableContainer} elevation={0} {...props} />,
					Toolbar: (props) => (
						<Toolbar pageTitle="ST Firmware Updates" className={classes.tableToolbar} {...props} />
					),
					Pagination: (props) => <Pagination {...props} />,
				}}
				actions={[
					(t) => ({
						icon: 'delete',
						tooltip: 'Delete Update',
						onClick: (event, t) => {
							setFirmwareToDelete(t);
							setIsOpenDialog(true);
						},
					}),
				]}
			/>
			<DeleteFirmwareDialog
				open={isOpenDialog}
				handleClose={handleClose}
				firmware={firmwareToDelete}
				deleteFirmware={deleteFirmware}
			/>
		</Layout>
	);
};

export default Firmware;
