import React, { FC } from 'react';
import './Layout.css';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../router/Routes';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import logo from '../../assets/logo.png';
import PilabsButton from '../accessories/PilabsButton';
import { signOut } from '../../actions/AuthActions';
import Footer from '@kinosi/licenses/components/Footer';

const theme = createTheme({
	palette: {
		primary: { main: '#00baff' },
	},
});

interface ILayoutProps {
	children?: JSX.Element | Array<JSX.Element>;
}

const Layout: FC<ILayoutProps> = function ({ children }) {
	return (
		<MuiThemeProvider theme={theme}>
			<Header />
			<div className={'st-content-filler'}>{children}</div>
			<Footer />
		</MuiThemeProvider>
	);
};

interface IHeaderProps {
	children?: React.ReactNode[];
}

const Header: FC<IHeaderProps> = function () {
	const selectedClass = 'st-header-link st-grey-text st-selected';
	const notSelectedClass = 'st-header-link st-grey-text';
	const { pathname: path } = useLocation();
	const userGroups = useSelector<IStore, string[]>((state) => state.user.info?.groups || []);

	const dispatch = useDispatch();

	return (
		<header className={'st-header'}>
			<nav className={'st-header-nav'}>
				<Link to={'/'}>
					<img className={'st-header-logo'} src={logo} alt={"pilabs's logo"} />
				</Link>
				<div className="st-header-nav-links">
					{Object.values(routes).map((v) =>
						v.roles.some((r) => userGroups.includes(r) || v.uri === '/') ? (
							<Link
								key={v.uri}
								className={`/${path.split('/')[1]}` === v.uri ? selectedClass : notSelectedClass}
								to={v.uri}
							>
								{v.label}
							</Link>
						) : null,
					)}
					<div
						style={{
							display: 'flex',
							height: 'fit-content',
							justifyContent: 'flex-end',
							padding: '1rem 0 1rem 2rem',
						}}
					>
						<PilabsButton onClick={() => dispatch(signOut())}>Logout</PilabsButton>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Layout;
