import { TablePagination } from '@material-ui/core';
import React from 'react';

const Pagination = (props: any) => (
	<TablePagination
		{...props}
		SelectProps={{
			renderValue: (value: any) => <div>Rows per page: {value}</div>,
		}}
	/>
);

export default Pagination;
