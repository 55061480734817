import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	btn: {
		display: 'inline-block',
		border: '2px solid',
		letterSpacing: '.5px',
		lineHeight: 'inherit',
		textTransform: 'uppercase',
		width: 'auto',
		fontWeight: 600,
		transitionDuration: '0.3s',
		transitionTimingFunction: 'ease-in-out',
		background: 'var(--pilabs-blue)',
		borderColor: 'var(--pilabs-blue)',
		color: '#fff',
		'&:hover': {
			background: 'var(--pi-labs-dark-blue)',
			cursor: 'pointer',
		},
		'&:focus': {
			outline: 'none',
		},
	},
	btnDisabled: {
		background: '#00baff70',
		pointerEvents: 'none',
	},
});

export type PLBtnProps = {
	type?: 'button' | 'reset' | 'submit' | undefined;
	padding?: string;
	onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	children?: JSX.Element | string | Array<JSX.Element | string>;
	disabled?: boolean;
	className?: string;
};

const PilabsButton = ({
	type,
	padding = '.5rem 1.5rem .4rem 1.5rem',
	onClick,
	disabled = false,
	className,
	children,
}: PLBtnProps) => {
	const classes = useStyles();
	return (
		<button
			type={type}
			className={`${classes.btn} ${className} ${disabled ? classes.btnDisabled : ''}`}
			onClick={onClick}
			style={{ padding }}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default PilabsButton;
