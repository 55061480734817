import React from 'react';
import { Redirect, Route, Switch, RouteProps } from 'react-router-dom';
import TempLandingPage from '../pages/TempLandingPage';
import Token from '../token/Token';
import HomePage from '../pages/HomePage';
import Loading from '../pages/Loading';
import Firmware from '../firmware/Firmware';
import LegalRoutes from '@kinosi/licenses/components/LegalRoutes';

// USER ROLES
const ROOT = 'Root';
const ADMIN = 'Admin';
// const FLEET_MANAGER = 'FleetManager';
// const FLEET_USER = 'FleetUser';

export const routes = {
	home: { uri: '/', label: 'Home', roles: [ROOT, ADMIN] },
	token: { uri: '/api-tokens', label: 'API Tokens', roles: [ROOT, ADMIN] },
	firmware: { uri: '/firmware', label: 'Firmware', roles: [ROOT, ADMIN] },
};

export interface IRoute {
	userRoles: Array<string>;
}

interface IProtectedRoute extends RouteProps {
	roles: string[];
	userRoles: string[];
}

const ProtectedRoute = ({ roles, userRoles, component: C, ...rest }: IProtectedRoute) => {
	const isSignedIn = Boolean(userRoles.length);
	return (
		<Route
			{...rest}
			render={(props) => {
				return isSignedIn && roles.some((r) => userRoles.includes(r)) && C ? (
					<C {...props} />
				) : isSignedIn ? (
					rest.location?.pathname === '/' ? (
						<TempLandingPage />
					) : (
						<Redirect to="/" />
					)
				) : (
					<Loading redirect={true} />
				);
			}}
		/>
	);
};

const Routes = (props: IRoute) => (
	<Switch>
		<ProtectedRoute
			exact
			path={routes.home.uri}
			roles={routes.home.roles}
			userRoles={props.userRoles}
			component={HomePage}
		/>
		<ProtectedRoute
			exact
			path={routes.token.uri}
			roles={routes.token.roles}
			userRoles={props.userRoles}
			component={Token}
		/>
		<ProtectedRoute
			exact
			path={routes.firmware.uri}
			roles={routes.firmware.roles}
			userRoles={props.userRoles}
			component={Firmware}
		/>
		<LegalRoutes />
	</Switch>
);

export default Routes;
